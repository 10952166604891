<template>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6482 59.3195C30.0284 59.3195 30.3951 59.4722 30.6633 59.7404C30.9314 60.0086 31.0842 60.3752 31.0842 60.7554V60.9693C31.0842 61.3495 30.9314 61.7161 30.6633 61.9843C30.3951 62.2525 30.0284 62.4053 29.6482 62.4053H22.2444V59.3161H29.6482V59.3195ZM29.6482 57.3098H20.5063C20.4723 57.3098 20.435 57.3166 20.401 57.3302C20.3671 57.3437 20.3365 57.3641 20.3128 57.3913C20.289 57.415 20.2686 57.4456 20.255 57.4795C20.2415 57.5135 20.2347 57.5474 20.2347 57.5848V64.1535C20.2347 64.2248 20.2618 64.2961 20.3162 64.347C20.3671 64.398 20.435 64.4251 20.5063 64.4251H29.6482C30.0997 64.4251 30.5512 64.3369 30.9688 64.1637C31.3863 63.9906 31.7665 63.736 32.0856 63.4135C32.4081 63.091 32.6594 62.7108 32.8325 62.2932C33.0056 61.8723 33.0939 61.4242 33.0939 60.9727V60.752C33.0939 59.8389 32.7306 58.9596 32.0823 58.3146C31.4373 57.6696 30.558 57.3064 29.6448 57.3064V57.3132L29.6482 57.3098Z"
      fill="#FF7235"
    />
    <path
      d="M18.5916 56.2267C11.1029 56.2267 7.64366 50.2588 7.64366 50.2588L12.6678 72.1513H24.4951L29.5192 50.2588C28.3548 52.0987 26.739 53.6128 24.8243 54.655C22.9131 55.6971 20.7676 56.2403 18.5882 56.2267H18.5916Z"
      fill="#FF7235"
    />
    <path
      d="M18.5916 56.2267C20.771 56.2437 22.9233 55.7039 24.8379 54.6583C26.7525 53.6128 28.3718 52.0987 29.5396 50.2588H7.64366C7.64366 50.2588 11.1029 56.2267 18.5916 56.2267Z"
      fill="#A15842"
    />
    <path
      d="M10.2304 143.855C9.81288 143.855 9.40212 143.753 9.03549 143.563C8.66547 143.369 8.34976 143.091 8.11213 142.748C7.8745 142.405 7.72174 142.011 7.66742 141.601C7.61311 141.186 7.66063 140.765 7.8066 140.375L14.1479 123.198H24.2303L12.4438 142.626C12.213 143.003 11.8871 143.315 11.5001 143.529C11.1131 143.746 10.6785 143.858 10.2338 143.855H10.2304Z"
      fill="black"
    />
    <path
      d="M60.6997 143.855C61.1173 143.855 61.5281 143.753 61.8947 143.563C62.2647 143.369 62.5804 143.091 62.8181 142.748C63.0557 142.405 63.2084 142.011 63.2628 141.601C63.3171 141.186 63.2696 140.765 63.1236 140.375L56.7822 123.198H46.6999L58.4864 142.626C58.7172 143.003 59.0431 143.315 59.4301 143.529C59.8171 143.746 60.2516 143.858 60.6963 143.855H60.6997Z"
      fill="black"
    />
    <path
      d="M70.6632 72.1548H0.266937V129.454H70.6632V72.1548Z"
      fill="#CA7358"
    />
    <path
      d="M63.2865 79.5315H7.64366V122.078H63.2865V79.5315Z"
      fill="#FFBE21"
    />
    <path
      d="M63.4087 100.871H7.64366"
      stroke="#CA7358"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M35.4668 93.3685C36.9605 93.3685 38.1724 92.1565 38.1724 90.6629C38.1724 89.1692 36.9605 87.9573 35.4668 87.9573C33.9731 87.9573 32.7612 89.1692 32.7612 90.6629C32.7612 92.1565 33.9731 93.3685 35.4668 93.3685Z"
      fill="#30242A"
    />
    <path
      d="M35.4668 114.029C36.9605 114.029 38.1724 112.817 38.1724 111.323C38.1724 109.83 36.9605 108.618 35.4668 108.618C33.9731 108.618 32.7612 109.83 32.7612 111.323C32.7612 112.817 33.9731 114.029 35.4668 114.029Z"
      fill="#30242A"
    />
    <path
      d="M128.472 143.855C130.214 143.855 131.626 142.444 131.626 140.703C131.626 138.962 130.214 137.551 128.472 137.551H72.7748C71.033 137.551 69.6211 138.962 69.6211 140.703C69.6211 142.444 71.033 143.855 72.7748 143.855H128.472Z"
      fill="#EAA58B"
    />
    <path
      d="M124.252 101.55L100.72 114.861L70.687 101.55H124.252Z"
      fill="#EAA58B"
    />
    <path
      d="M129.585 101.55H47.389L48.7775 96.0367H84.8566L98.6188 42.2202C100.493 34.891 104.756 28.3935 110.731 23.7529C116.713 19.109 124.062 16.5901 131.629 16.5901C150.144 16.5901 163.692 34.0423 159.099 51.98L150.595 85.2313C149.404 89.8991 146.688 94.0338 142.886 96.9872C139.08 99.9407 134.403 101.546 129.585 101.546V101.55Z"
      fill="#3EC1F3"
    />
    <path
      d="M109.621 85.2314L114.818 64.9072H80.3755C73.62 64.9072 67.0614 67.1545 61.7249 71.2961C56.3884 75.4376 52.5864 81.2358 50.9094 87.7808L47.389 101.55H88.6043C93.4214 101.55 98.1028 99.9441 101.905 96.9907C105.71 94.0373 108.423 89.8991 109.618 85.2314H109.621Z"
      fill="#3EC1F3"
    />
    <path
      d="M49.633 95.4937H92.6814"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M85.0161 95.2899L92.678 65.3315"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M154.598 64.2112H124.279C117.524 64.2112 110.962 66.4585 105.629 70.6034C100.292 74.745 96.4903 80.5466 94.8167 87.0882L91.293 100.857"
      stroke="#4756DF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M103.775 108.203H97.4713V139.251H103.775V108.203Z"
      fill="#EAA58B"
    />
  </svg>
</template>
